<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    ref="drawer"
    v-model="drawer"
    :expand-on-hover="expandOnHover"
    mobile-breakpoint="960"
    app
    dark
    :mini-variant.sync="mini"
    :mini-variant-width="hasScroll ? 85 : 75"
    width="260"
    v-bind="$attrs"
  >
    <!-- :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'" -->
    <!-- <template v-if="barColor" #img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template> -->
    <v-list-item>
      <v-list-item-content class="pb-0">
        <v-list-item-title class="text-uppercase font-weight-regular text-h3">
          <!-- <v-img class="logo-mini" contain position="right" :src="require(`@/assets/favicon.png`)" height="22" /> -->
          <span class="logo-normal">{{ title }}</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="appType == 'ebib'" dense>
      <v-list-item-content :class="{ 'display-1': true, 'text-truncate': mini }">
        {{ biblioteca }}
      </v-list-item-content>
    </v-list-item>
    <v-divider class="mb-1" />
    <v-list ref="listItems" expand nav>
      <div></div>
      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item" :mini="mini"> </base-item-group>
        <base-item v-else :key="`item-${i}`" :item="item" :mini="mini" />
      </template>
      <div></div>
    </v-list>
    <template #append>
      <v-divider class="mb-1" />
      <v-list-item dense>
        <v-list-item-content class="py-0">
          <v-list-item-title>
            <span class="text-caption">v5.0</span>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="my-0">
          <span class="text-caption">{{ libraryCode }}</span>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>
<script>
import { mapState } from "vuex"
import axios from "../../../../plugins/axios"
export default {
  name: "DashboardCoreDrawer",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    hasScroll: false,
    title: "",
    mini: null,
    menuDisabled: {},
    items: [
      {
        icon: "mdi-view-dashboard",
        title: "Dashboard",
        to: "/"
      }
    ]
  }),
  metaInfo() {
    return {
      title: this.title
    }
  },
  computed: {
    ...mapState(["barColor", "barImage"]),
    appType() {
      return this.$store.getters.appType
    },
    accountMenu() {
      return this.$store.getters.accountMenu
    },
    accountMenuList() {
      return this.$store.getters.accountMenuList
    },
    biblioteca() {
      if (this.$store.getters.libraryData) {
        return this.$store.getters.libraryData.library.public_name
      }
      return ""
    },
    libraryCode() {
      if (this.$store.getters.libraryData) {
        return this.$store.getters.libraryData.library.code
      }
      return ""
    },
    accountType() {
      return this.$store.getters.accountLevel
    },
    menu() {
      if (this.$store.getters.accountMenuDefault) {
        return this.$store.getters.accountMenuDefault
      }
      return []
    },
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val)
      }
    },
    computedItems() {
      return this.items.map(this.mapItem)
    }
  },
  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val)
    },
    $route(to) {
      this.$nextTick(() => {
        setTimeout(this.changeHasScroll, 100)
      })
    },
    menu() {
      this.menuloaded()
    },
    accountMenuList() {
      this.menuloaded()
    }

    // $route(to) {
    //   this.$refs.listItems.$children.map(itm => {
    //     if (itm.item.group) {
    //       if (itm.item.group.indexOf(to.path.split("/")[1]) == 1) {
    //         itm.expand = true
    //         itm.$el.classList.add("v-list-group--active")
    //         itm.$el.classList.className = "v-list-group--active"
    //         itm.$el.classList.add("white--text")
    //         itm.$el.children[0].classList.add("v-list-item--active")
    //         itm.$el.children[0].ariaExpanded = "true"
    //         itm.$el.children[1].style.display = ""
    //         setTimeout(() => {
    //           itm.expand = true
    //           itm.$el.children[0].ariaExpanded = "true"
    //           itm.$el.children[0].classList.add("v-list-item--active")
    //           itm.$el.children[1].style.display = ""
    //           itm.$el.classList.add("v-list-group--active")
    //           itm.$el.classList.className = "v-list-group--active"
    //           itm.$el.classList.add("white--text")
    //         }, 1000)
    //       }
    //     }
    //   })
    // }
  },
  mounted() {
    //this.$log("created admin drawer")
    axios.get('app_system_dictionary?where={"value":"app_type"}').then(response => {
      this.title = response.data._items[0].content.find(itm => itm.value == this.appType).name
    })
    this.menuloaded()
  },
  updated() {
    this.changeHasScroll()
  },
  methods: {
    changeHasScroll() {
      //this.$log("has1  ", this.$refs.drawer.$children[4].$el.scrollHeight)
      //this.$log("has2  ", this.$refs.listItems.$el.scrollHeight)
      //this.$log("has3  ", this.$refs.drawer.$el.clientHeight)
      this.hasScroll = this.$refs.listItems.$el.scrollHeight > this.$refs.drawer.$el.clientHeight - 140
    },
    // loadMenu() {
    //   axios
    //     .get(
    //       'app_menu?where={"owner.app_type":"' +
    //         this.appType +
    //         '","menu_type":"' +
    //         this.accountMenu +
    //         '","owner.user_type":"' +
    //         this.accountType +
    //         '"}'
    //     )
    //     .then(response => {
    //       if (this.accountMenuList) {
    //         this.accountMenuList.map(men => {
    //           if (!men.enabled) {
    //             this.menuDisabled[men.path] = true
    //           }
    //           if (men.items) {
    //             men.items.map(sub => {
    //               if (!sub.enabled) {
    //                 this.menuDisabled[men.path + "/" + sub.path] = true
    //               }
    //             })
    //           }
    //         })
    //       }
    //       const newmenu = []
    //       this.generateMenu(response.data._items[0].content, newmenu, false, "")
    //       this.items = [...newmenu]
    //       //this.$log("menu ", this.items)
    //     })
    // },
    menuloaded() {
      //this.$log("menuloaded1 ", this.menu)
      //this.$log("menuloaded2 ", this.accountMenuList)
      if (this.menu.length > 0 && this.accountMenuList) {
        this.accountMenuList.map(men => {
          if (!men.enabled) {
            this.menuDisabled[men.path] = true
          }
          if (men.items) {
            men.items.map(sub => {
              if (!sub.enabled) {
                this.menuDisabled[men.path + "/" + sub.path] = true
              }
            })
          }
        })
      }
      const newmenu = []
      this.generateMenu(this.menu, newmenu, false, "")
      this.items = [...newmenu]
      //this.$log("menu ", this.items)
    },
    generateMenu(content, menu, sub, path) {
      content.map(itm => {
        //this.$log("menu itm ", itm)
        if (!this.menuDisabled[path + itm.path]) {
          const men = {}
          itm.name = itm.component + itm.path
          if (itm.items) {
            men.group = "/" + itm.path
            men.icon = itm.icon
            men.title = itm.title
            men.children = []
            this.generateMenu(itm.items, men.children, true, itm.path + "/")
          } else {
            men.icon = itm.icon
            men.title = itm.title
            men.to = (sub ? "" : "/") + itm.path
          }
          menu.push(men)
        }
      })
    },
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title)
      }
    }
  }
}
</script>
